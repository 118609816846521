/* Base Reset */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 100%; /* Default font size for mobile */
  scroll-behavior: smooth;
}

body {
  font-family: sans-serif;
  color: var(--black);
  background-color: var(--white);
}



:root {
  --container-primary-background: #F3F3F3;
  --white: #ffffff;
  --green: #36CB61;
  --black: #242424;
  --blue: #1AA3F3;

  /*Container*/
--container-max-width: 1200px;
  --container-min-width: 320px;
  /* Font Sizes */
  --font-small: 0.875rem;   /* 14px */
  --font-regular: 1rem;     /* 16px */
  --font-large: 1.125rem;   /* 18px */
  --font-hero: 2.25rem;     /* 36px */
  --font-h1: 1.75rem;       /* 28px */
  --font-h2: 1.5rem;        /* 24px */
  --font-h3: 1.25rem;       /* 20px */
  --font-h4: 1.125rem;      /* 18px */
  --font-h5: 1rem;          /* 16px */
  --font-h6: 0.875rem;      /* 14px */

  /* Margin Sizes */
  --margin-xsmall: 0.25rem; /* 4px */
  --margin-small: 0.5rem;   /* 8px */
  --margin-medium: 1rem;    /* 16px */
  --margin-large: 2.5rem;   /* 40px */
  --margin-xlarge: 3rem;    /* 48px */
  --margin-xxlarge: 4rem;
  --margin-xxxlarge: 5rem;

  /* Padding Sizes */
  --padding-xsmall: 0.25rem; /* 4px */
  --padding-small: 0.5rem;   /* 8px */
  --padding-medium: 1rem;    /* 16px */
  --padding-large: 2.5rem;   /* 40px */
  --padding-xlarge: 3rem;    /* 48px */
  --padding-xxlarge: 4rem;
  --padding-xxxlarge: 5rem;

  --main-container-side-padding: 5rem;
}


/*Page base styling*/
.container {
  max-width: var(--container-max-width);
  min-width: var(--container-min-width);
  padding: 0 var(--main-container-side-padding);
  margin: auto;
}

/*Container margin*/
.page-section-margin{
  margin-bottom: var(--margin-xxxlarge);
}

/*Colors*/

/* Typography */
.small {
  font-size: var(--font-small);
}
.regular {
  font-size: var(--font-regular);
}

p {
  font-size: var(--font-regular);
  margin-bottom: var(--margin-medium);
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: var(--margin-medium);
}

h1 {
  font-size: var(--font-h1);
}

h2 {
  font-size: var(--font-h2);
}

h3 {
  font-size: var(--font-h3);
}

h4 {
  font-size: var(--font-h4);
}

h5 {
  font-size: var(--font-h5);
}

h6 {
  font-size: var(--font-h6);
}

.large {
  font-size: var(--font-large);
}

.hero {
  font-size: var(--font-hero);
}

/* Typography Weights*/

.bold{
  font-weight: bold;
}

/* Utility Classes */
.m-0 { margin: 0; }
.p-0 { padding: 0; }


/* Flexbox Utility */
.flex {
  display: flex;
}

/* Flex utilities */
.flex-column { flex-direction: column; }
.flex-row { flex-direction: row; }
.align-center { align-items: center; }
.justify-center { justify-content: center; }

/*margin utilities*/
.m-r-small{
  margin-right: var(--margin-small);
}
.m-r-medium{
  margin-right: var(--margin-medium);
}

/* Tablet Styles (max-width: 768px) */
@media (max-width: 768px) {
  :root {
    /* Font Sizes */
    --font-small: 0.925rem;   /* 14.8px */
    --font-regular: 1rem;     /* 16px */
    --font-large: 1.125rem;   /* 18px */
    --font-hero: 2rem;        /* 32px */
    --font-h1: 1.625rem;      /* 26px */
    --font-h2: 1.375rem;      /* 22px */
    --font-h3: 1.125rem;      /* 18px */
    --font-h4: 1rem;          /* 16px */
    --font-h5: 0.875rem;      /* 14px */
    --font-h6: 0.75rem;       /* 12px */

    /* Margin Sizes */
    --margin-xsmall: 0.25rem; /* 4px */
    --margin-small: 0.5rem;   /* 8px */
    --margin-medium: 0.75rem;  /* 12px */
    --margin-large: 2rem;     /* 32px */
    --margin-xlarge: 2.5rem;  /* 40px */
    --margin-xxlarge: 3.5rem;
    --margin-xxxlarge: 4.5rem;

    /* Padding Sizes */
    --padding-xsmall: 0.25rem; /* 4px */
    --padding-small: 0.5rem;   /* 8px */
    --padding-medium: 0.75rem;  /* 12px */
    --padding-large: 2rem;     /* 32px */
    --padding-xlarge: 2.5rem;  /* 40px */
    --padding-xxlarge: 3.5rem;
    --padding-xxxlarge: 4.5rem;

    --main-container-side-padding: 3rem;
  }
}

/* Mobile Styles (max-width: 500px) */
@media (max-width: 500px) {
  :root {
    /* Font Sizes */
    --font-small: 0.875rem;   /* 14px */
    --font-regular: 0.925rem;  /* 14.8px */
    --font-large: 1rem;       /* 16px */
    --font-hero: 1.75rem;     /* 28px */
    --font-h1: 1.375rem;      /* 22px */
    --font-h2: 1.125rem;      /* 18px */
    --font-h3: 1rem;          /* 16px */
    --font-h4: 0.875rem;      /* 14px */
    --font-h5: 0.75rem;       /* 12px */
    --font-h6: 0.625rem;      /* 10px */

    /* Margin Sizes */
    --margin-xsmall: 0.25rem; /* 4px */
    --margin-small: 0.5rem;   /* 8px */
    --margin-medium: 0.75rem;  /* 12px */
    --margin-large: 1.5rem;   /* 24px */
    --margin-xlarge: 2rem;    /* 32px */
    --margin-xxlarge: 3rem;
    --margin-xxxlarge: 4rem;

    /* Padding Sizes */
    --padding-xsmall: 0.25rem; /* 4px */
    --padding-small: 0.5rem;   /* 8px */
    --padding-medium: 0.75rem;  /* 12px */
    --padding-large: 1.5rem;   /* 24px */
    --padding-xlarge: 2rem;    /* 32px */
    --padding-xxlarge: 3rem;
    --padding-xxxlarge: 4rem;

    --main-container-side-padding: 1rem;
  }
}
