/*Buttons*/


.button {
    background-color: var(--green);
    color: var(--white);
    border: none;
    border-radius: 5px;
    padding: var(--padding-small) var(--padding-medium);
    font-size: var(--font-regular);
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
}
.button-outline {
    background-color: transparent;
    color: var(--black);
    border: 1px solid var(--black); /* No border */
}

/*nav*/

.menu-item{cursor: pointer;}
.nav-container-fix{
    justify-content: space-between;
}

.nav-container-mobile-fix{
    padding-left:var(--main-container-side-padding);
    padding-right:var(--main-container-side-padding);
}

/*Hero sections*/
.hero-container{
    overflow: hidden;
    background-color: var(--container-primary-background);
    border-radius: 40px;
    display:flex;
    flex-direction: row;
    align-items: stretch;
}

.hero-section{
    flex:1;
    padding: var(--padding-xxxlarge) var(--padding-xlarge);
    display:flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--margin-medium);
}

.hero-section-img{
    flex:1;
    height: 500px;
    background-color: var(--container-primary-background);
    background-size: cover; /* Ensures the image covers the whole area */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
}

.hero-section-logo{
    flex:1;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-button-container{
    display:flex;
    gap: var(--margin-medium);
    flex-direction: row;
}


/*Sliders*/
.slider-container{
    padding: var(--padding-xxxlarge) 0px;
    background-clip: padding-box;
    background-color: var(--container-primary-background);
    min-width: var(--container-min-width);
}
.slider-container-nobackground{
    background-color: transparent;
    padding: 0px;
    min-width: var(--container-min-width);
}

.slider-text-container{
    margin-bottom: var(--margin-large);
    width: 50%;
}


.slider-button{
    min-width: fit-content;
    border-radius: 100px;
    width: 50%;
}

.slide-container{
    min-width: var(--container-min-width);
    flex:1;
    overflow-x: scroll;
    display:flex;
    flex-direction: row;
    gap: var(--margin-medium);
    scrollbar-width: none;     /* Hide scrollbar in Firefox */
    -ms-overflow-style: none;  /* Hide scrollbar in IE and Edge */
}

.slide-container::-webkit-scrollbar {
    display: none;
}

/*Cards*/
/*Service Card*/

.service-card-img{
    background-color: var(--container-primary-background);
    background-size: cover; /* Ensures the image covers the whole area */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    height: 300px;
    width: 340px;
    border-radius: 15px;
    margin-bottom: var(--margin-medium);
    padding: var(--padding-medium);
    display: flex;
    justify-content: flex-end;
}

.card-hover-icon{
    transform: rotate(180deg);
    font-size: var(--font-h3);
    color: var(--white);
    background-color:var(--blue);
    padding: var(--padding-medium);
    border-radius: 100%;
}


/*Product & service*/
.listing-container{
    display: flex;
    flex-direction: row;
    gap: var(--margin-large);
   flex-wrap: wrap;

}

.listing-info{
    flex: 0.35;
    
}

.listing-img{
    flex: 0.65;
    border-radius: 30px;
    height: 650px;
    width: auto;
    background:no-repeat center ;
    background-color: var( --container-primary-background);
}


/* breadcrumb */
.breadcrumb{
    margin: var(--margin-large) 0px;
}

.breadcrumb-link {
    text-decoration: none;
}

.breadcrumb-link:hover {
    text-decoration: underline; 
}

.breadcrumb span {
    margin: 0px var(--margin-small); 
}


/*Footer*/
.footer-container{
    background-color: var(--container-primary-background);
    padding-top: var(--padding-xxlarge);
    padding-bottom: var(--padding-xxlarge);
    min-width: var(--container-min-width);
}

.footer-sections-container{
    display: flex;
    flex-direction: row;
    gap: var(--margin-medium);
    margin-bottom: var(--margin-large);
}

.footer-section {
    flex: 1;
}

.footer-section p {
    max-width:300px;
}

.footer-sections-container .footer-section:first-child{
    flex: max-content;
}

.footer-section img , .footer-section h6 {
    margin-bottom: var(--margin-large);
}

.footer-section:first-child img {
    height: 40px;
    width: auto;
}

.footer-sections-container ul li {
    list-style: none;
    font-size: var(--font-small);
    margin-bottom: var(--margin-small);
}

.footer-sections-container ul li a {
    color: var(--black);
}

.footer-container hr{
    width: 100%;
    margin-bottom: var(--margin-medium);
}

input{
    width: 100%;
    height: 100%;
}

/*Tablet*/
@media (max-width: 900px) {

    /*Hero*/
    .hero-container{
        border-radius: 20px;
    }
    .home-hero-logo{
        height: 150px;
        width: auto;
    }
    .hero-section-logo{
        height: 430px;
    }

    .hero-section-img{
        min-height: 430px;
    }


    /*slider*/
    .slider-text-container{
        width: 80%;
    }

    /*Listing*/
    .listing-container{
        flex-direction: column-reverse;
    }

    .listing-info{
        flex: none;
        
    }
    
    .listing-img{
        flex: none;
        width: 100%;
        height: 550px;
    }


    /*Footer*/
    .footer-section img{
        margin-bottom: 0px;
    }

    .footer-section:first-child p {
        display: none;
    }

    .footer-sections-container{
        flex-direction: column;
        gap: var(--margin-large);
    }

    .footer-section h6 {
        margin-bottom: var(--margin-medium);
    }

}

/*Mobile*/
@media (max-width: 850px) {



    /*Hero*/
    .home-hero-logo{
        height: 60px;
        width: auto;
    }

    .hero-container{
        gap: 0px;
        flex-direction: column-reverse;
        align-items: flex-start;
        border-radius: 15px;
    }

    .hero-section{
        padding:  var(--padding-large)  var(--padding-large) var(--padding-xxlarge) var(--padding-large);
    }
    .hero-section-logo{
        padding-top: var(--padding-xxlarge);
        padding-left: var(--padding-large);

    }
    .hero-section-img{
        flex: none;
        width: 100%;
        height: 280px;
    }

    .hero-button-container{
        flex-direction: column;
    }


    .listing-img{
        flex: none;
        width: 100%;
        height: 450px;
    }


    .category-cards-container{
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
    }

    .category-card-container{
        margin-top: 0px;
        margin-bottom: 0px;
        width: max-content;
        margin-right: 10px;
    }


}